export default {
	form: {
		saved: {
			success: 'saved successfully',
		},
		field: {
			name: {
				label: 'Name',
			},
		},
		action: {
			cancel: {
				present: 'Cancel',
			},
			add: {
				present: 'Add',
			},
			save: {
				present: 'Save',
			},
			invite: {
				present: 'Invite',
				past: 'Invited',
			},
			delete: {
				present: 'Delete',
			},
			edit: {
				present: 'Edit',
			},
			send: {
				present: 'Send',
			},
			accept: {
				present: 'Accept',
			},
			confirm: {
				present: 'Confirm',
			},
			decline: {
				present: 'Decline',
			},
			register: {
				present: 'Register',
			},
			login: {
				present: 'Login',
			},
			logout: {
				present: 'Logout',
			},
			registerTeam: {
				present: 'Register team',
			},
		},
	},

	team: {
		member: {
			form: {
				title: {
					create: 'Create new member',
					edit: 'Edit member',
					delete: 'Delete member',
				},
				field: {
					email: {
						label: 'Email',
					},
					name: {
						label: 'Name',
					},
					password: {
						abel: 'Password',
					},
					role: {
						label: 'Role',
						enum: {
							owner: {
								label: 'Owner',
							},
							admin: {
								label: 'Admin',
							},
							member: {
								label: 'Member',
							},
							readOnly: {
								lbel: 'Read only',
							},
						},
					},
					isPlayer: {
						label: 'Are you a player?',
						help: 'It will be show on the ranking',
					},
					playerPoints: {
						label: 'Current points',
					},
					playerGoal: {
						labl: 'Points goal',
					},
				},
			},
		},
	},

	entity: {
		user: {
			label: 'User',
		},
		invite: {
			one: 'Invite',
			many: 'Invites',
		},
		team: {
			one: 'Team',
			many: 'Teams',
		},
		member: {
			one: 'Member',
			many: 'Members',
			action: {
				delete: {
					confirm: 'Are you sure you want to delete this member?',
				},
			},
		},

		organization: {
			action: {
				delete: {
					confirm: 'Are you sure you want to delete this organization?',
				},
			},
		},

		game: {
			action: {
				delete: {
					confirm: 'Are you sure you want to delete this team?',
				},
			},
		},
	},

	organization: {
		form: {
			title: {
				delete: 'Delete organization',
			},
		},
	},

	game: {
		form: {
			title: {
				delete: 'Delete team',
			},
		},
	},
};
