export default {
	form: {
		title: {
			label: 'Título',
		},
		description: {
			label: 'Descrição',
		},

		saved: {
			success: 'Salvo com sucesso',
		},

		name: 'Nome',
		field: {
			name: {
				label: 'Nome',
			},
		},

		action: {
			create: 'Criar',
			cancel: {
				present: 'Cancelar',
			},
			add: {
				present: 'Adicionar',
			},
			save: {
				present: 'Salvar',
			},
			invite: {
				present: 'Convidar',
				past: 'Convidado',
			},
			delete: {
				present: 'Excluir',
			},
			edit: {
				present: 'Editar',
			},
			send: {
				present: 'Enviar',
			},
			accept: {
				present: 'Aceitar',
			},
			confirm: {
				present: 'Confirmar',
			},
			decline: {
				present: 'Recusar',
			},
			register: {
				present: 'Registrar',
			},
			login: {
				present: 'Entrar',
			},
			logout: {
				present: 'Sair',
			},
			registerTeam: {
				present: 'Registrar time',
			},
		},
	},

	team: {
		title: 'Time',
		member: {
			form: {
				title: {
					create: 'Criar novo membro',
					edit: 'Editar membro',
					delete: 'Excluir membro',
				},
				field: {
					email: {
						label: 'Email',
					},
					name: {
						label: 'Nome',
					},
					password: {
						label: 'Senha',
					},
					role: {
						label: 'Cargo',
						enum: {
							owner: {
								label: 'Dono',
							},
							admin: {
								label: 'Administrador',
							},
							member: {
								label: 'Membro',
							},
							readOnly: {
								label: 'Somente leitura',
							},
						},
					},
					isPlayer: {
						label: 'Você é um jogador?',
						help: 'Será exibido no ranking',
					},
					playerPoints: {
						label: 'Pontos atuais',
					},
					playerGoal: {
						label: 'Pontos para marcar',
					},
				},
			},
		},
	},

	entity: {
		user: {
			label: 'Usuário',
		},
		invite: {
			one: 'Convite',
			many: 'Convites',
		},
		team: {
			one: 'Time',
			many: 'Times',
		},
		member: {
			one: 'Membro',
			many: 'Membros',
			action: {
				delete: {
					confirm: 'Tem certeza que deseja deletar esse membro?',
				},
			},
		},

		organization: {
			action: {
				delete: {
					confirm: 'Tem certeza que deseja deletar essa organização?',
				},
			},
		},

		game: {
			action: {
				delete: {
					confirm: 'Tem certeza que deseja deletar esse time?',
				},
			},
		},
	},

	organization: {
		name: 'Nome da Organização',

		title: 'Organização',

		create: 'Criar nova organização',

		update: {
			success: 'Salvo com sucesso',
		},

		action: {
			delete: {
				title: 'Deletar organização',

				error: {
					with_games: 'delete todas os times da organização para excluir',
				},
			},
		},
	},

	game: {
		name: 'Nome do Time',

		create: 'Criar novo time',

		update: {
			success: 'Salvo com sucesso',
		},

		action: {
			delete: {
				title: 'Deletar time',

				error: {
					with_players: 'delete todos os participantes do time para excluir',
				},
			},
		},
	},

	award: {
		type: {
			firstPlace: {
				title: 'Primeiro lugar',
			},
			secondPlace: {
				title: 'Segundo lugar',
			},
			thirdPlace: {
				title: 'Terceiro lugar',
			},
		},
	},

	awards: {
		title: 'Premiações',
	},

	podium: {
		awards: {
			title: 'Premiações do podio',
			create: {
				success: {
					label: 'Premiações do podio salvas com sucesso',
				},
			},
			update: {
				success: {
					label: 'Premiações do podio alteradas com sucesso',
				},
			},
			delete: {
				title: 'Excluir premiações do podio',
				confirm: 'Você tem certeza que deseja excluir essas premiações do pódio? Esta ação não pode ser desfeita',
				success: {
					label: 'Premiações do podio deletadas com sucesso',
				},
			},
		},
	},

	error: {
		title: 'Erro',

		on: {
			save: 'erro ao salvar o registro',
		},
	},

	warning: {
		game_reached_limit: 'Você chegou ao limite de times contratados. Contate o suporte para saber mais',
	},

	leaderboard: {
		title: 'Ranking',
	},

	settings: {
		title: 'Configurações',
	},

	player: {
		goal: 'Meta',

		edit: {
			title: 'Editar jogador',
		},
	},

	application: {
		abbreviation: 'app',

		signedInWith: `conectado como`,

		signOut: 'sair',
	},

	colorMode: {
		lightMode: 'Modo claro',
		darkMode: 'Modo escuro',
	},

	login: {
		error: {
			invalidEmailOrPassword: 'e-mail ou senha inválidos',
		},
	},

	password: {
		label: 'senha',
	},

	sidebar: {
		help: 'Ajuda',
		contact: 'Contato',
	},

	avatar: {
		warning: {
			imageMustBe: 'A imagem deve ser .jpeg ou .png com menos de 1mb',
		},
	},
};
